import { Box } from "@mui/material";
import {
   Button,
   Divider,
   Heading,
} from "@nn-design-system/react-component-library";
import { useState } from "react";
import PasswordForm from "./PasswordForm";
import SMSForm from "./SMSForm";
import { colourInteractionDefault } from "../../../Config/colors";
import BankIDForm from "./BankIdForm";

const LoginFormContainer = () => {
   const [isPasswordButtonSelected, setIsPasswordButtonSelected] =
      useState<boolean>(false);

   const handleButtonChange = (isPassword: boolean) => {
      if (isPassword !== isPasswordButtonSelected) {
         setIsPasswordButtonSelected(isPassword);
      }
   };

   return (
      <>
         {/* <Box
            sx={{
               marginTop: "1.5rem",
               display: "flex",
               gap: "1rem",
               flexWrap: "wrap",
               width: "100%",
               "@media (max-width: 500px)": {
                  gap: ".25rem",
                  justifyContent: "space-around",
               },
            }}
         >
            <Box
               sx={{
                  borderBottom: !isPasswordButtonSelected
                     ? `solid 2px ${colourInteractionDefault}`
                     : "",

                  "& > *": {
                     color: isPasswordButtonSelected
                        ? "black !important"
                        : `${colourInteractionDefault} !important`,
                     "& > span": {
                        "@media (max-width: 500px)": {
                           fontSize: ".9rem",
                        },
                     },
                  },
               }}
            >
               <Button
                  variant={"PlainMedium"}
                  onClick={() => handleButtonChange(false)}
               >
                  Přihlášení přes SMS
               </Button>
            </Box>
            <Box
               sx={{
                  borderBottom: isPasswordButtonSelected
                     ? `solid 2px ${colourInteractionDefault}`
                     : "",

                  "& > *": {
                     color: !isPasswordButtonSelected
                        ? "black !important"
                        : `${colourInteractionDefault} !important`,
                     "& > span": {
                        "@media (max-width: 500px)": {
                           fontSize: ".9rem",
                        },
                     },
                  },
               }}
            >
               <Button
                  variant={"PlainMedium"}
                  onClick={() => handleButtonChange(true)}
               >
                  Přihlášení pomocí hesla
               </Button>
            </Box>
         </Box>
         
        {isPasswordButtonSelected ? <PasswordForm /> : <SMSForm />} */}

         <Divider mt="1rem" mb="1rem" />
         <Heading hasMargin={false} mb="1.5rem" variant="Medium">
            Přihlášení přes sms
         </Heading>

         <SMSForm />

         <Divider mt="1rem" mb="1rem" />
         <Heading hasMargin={false} mb="1.5rem" variant="Medium">
            Přihlášení přes Bank ID
         </Heading>
        <BankIDForm />
      </>
   );
};

export default LoginFormContainer;
