import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
   AttentionBlock,
   Button,
   Paragraph,
} from "@nn-design-system/react-component-library";
import useCustomState from "../../../CustomHooks/useCustomState";
import useFetch from "../../../CustomHooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { generateGUID } from "../../../redux/reducers/guidReducer";
import { setToken } from "../../../redux/reducers/tokenReducer";
import { useNavigate } from "react-router-dom";
import {
   login,
   setPersonModel,
   setUserAuthenticationStatus,
} from "../../../redux/reducers/authReducer";
import { StoreType } from "../../../redux/store/store";
import CustomInput from "../../CustomInputs/CustomInput";
import { checkLength } from "../../../Utils/CheckLength";

interface SMSFormType {
   clientID: string;
   SMSCode: string;
}

type SMSApiResponse = {
   description: string;
   jwtToken: string;
   statusCode: number;
};

const SMSForm = () => {
   const navigate = useNavigate();
   const {
      state: SMSForm,
      stateValidation: SMSFormValidation,
      setStateHandler: setFormHandler,
      resetStateValidation,
      updateStateValidation,
   } = useCustomState<SMSFormType>({
      clientID: "",
      SMSCode: "",
   });

   const guid = useSelector((state: StoreType) => state.guidState.guid);
   const { token } = useSelector((state: StoreType) => state.tokenState);
   const [wasSMSSend, setWasSMSSend] = useState(false);

   const dispatch = useDispatch();

   const {
      sendRequest: sendRequestSendSMS,
      data: dataSendSMS,
      loading: loadingSendSMS,
      error: errorSendSMS,
      resetFetch: resetFetchSendSMS,
      fetchFired: fetchFiredSendSMS,
   } = useFetch<SMSApiResponse>();

   const {
      sendRequest: reguestCheckSMS,
      data: dataCheckSMS,
      error: errorCheckSMS,
      loading: loadingCheckSMS,
      resetFetch: resetFetchCheckSMS,
   } = useFetch<SMSApiResponse>();

   const {
      sendRequest: reguestGetInsuredClient,
      data: dataGetInsuredClient,
      error: errorGetInsuredClient,
      loading: loadingGetInsuredClient,
      resetFetch: resetFetchGetInsuredClient,
   } = useFetch();

   const callSMSSend = () => {
      sendRequestSendSMS({
         method: "POST",
         url: process.env.REACT_APP_BaseURL! + process.env.REACT_APP_SendSMS,
         headers: {
            "Content-Type": "application/json",
         },
         data: {
            transactionId: guid,
            loginName: SMSForm.clientID,
            smsCode: null,
         },
      });
   };

   const callSMSCheck = () => {
      reguestCheckSMS({
         method: "POST",
         url: process.env.REACT_APP_BaseURL! + process.env.REACT_APP_CheckSMS,
         headers: {
            "Content-Type": "application/json",
         },
         data: {
            transactionId: guid,
            loginName: SMSForm.clientID,
            smsCode: SMSForm.SMSCode.replace(/\s+/g, ""),
         },
      });
   };

   const callGetInsuredClient = () => {
      reguestGetInsuredClient({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! +
            process.env.REACT_APP_GetInsuredClientList,
         // process.env.REACT_APP_GetInsuredClient,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            personalId: SMSForm.clientID.replace(/\s+/g, ""),
         },
      });
   };
   useEffect(() => {
      if (token && dataCheckSMS) {
         callGetInsuredClient();
      }
   }, [token]);

   useEffect(() => {
      if (dataCheckSMS) {
         if (dataCheckSMS.description === "Ok") {
            dispatch(setToken(dataCheckSMS.jwtToken));
         } else if (dataCheckSMS.description === "Neplatný autorizační kód.") {
            updateStateValidation("SMSCode", "Nesprávný kód");
         }
      }
   }, [dataCheckSMS]);

   useEffect(() => {
      if (dataSendSMS && dataSendSMS.statusCode === 0 && !errorSendSMS) {
         setWasSMSSend(true);
      }
   }, [fetchFiredSendSMS]);

   useEffect(() => {
      if (dataGetInsuredClient) {
         dispatch(
            setPersonModel({
               firstName:
                  dataGetInsuredClient.result.insuredPersonList[0].firstname,
               lastName:
                  dataGetInsuredClient.result.insuredPersonList[0].lastname,
               personalId:
                  dataGetInsuredClient.result.insuredPersonList[0].personalId,
               insuredPersons: dataGetInsuredClient.result.insuredPersonList,
            })
         );
         dispatch(login());
         dispatch(setUserAuthenticationStatus("authorized"));
         navigate("/personSelection");
      }
   }, [dataGetInsuredClient]);

   useEffect(() => {
      if (!guid) {
         dispatch(generateGUID());
      }
   }, [guid]);

   const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (wasSMSSend) {
         if (
            !SMSForm.SMSCode ||
            SMSForm.SMSCode.replace(/\s+/g, "").length !== 8
         ) {
            updateStateValidation("SMSCode", "SMS kód musí mít 8 znaků");
         } else {
            resetStateValidation();
            callSMSCheck();
         }
      } else {
         if (
            !SMSForm.clientID ||
            (SMSForm.clientID.replace(/[\s/]+/g, "").length !== 10 &&
               SMSForm.clientID.replace(/[\s/]+/g, "").length !== 9)
         ) {
            updateStateValidation(
               "clientID",
               "Rodné číslo musí mít 9 či 10 znaků"
            );
         } else {
            resetStateValidation();
            callSMSSend();
         }
      }
   };

   const displayAttentionBlock = () => {
      if (errorCheckSMS) {
         return (
            <AttentionBlock mt="1rem" variant="Error">
               <Paragraph>
                  {/* Omlouváme se, ale ověření SMS je aktuálně mimo provoz. Prosím
           využijte možnost přihlášení "Vyplním si sám/a", nebo nás
           kontaktujte na emailu dotazy@nn.cz nebo na infolince 244 090
           800. */}
                  Nesprávný kód
               </Paragraph>
            </AttentionBlock>
         );
      }
      if (errorGetInsuredClient) {
         return (
            <AttentionBlock mt="1rem" variant="Error">
               <Paragraph>
                  Omlouváme se, ale ověření SMS je aktuálně mimo provoz. Prosím
                  využijte možnost přihlášení "Vyplním si sám/a", nebo nás
                  kontaktujte na emailu dotazy@nn.cz nebo na infolince 244 090
                  800.
               </Paragraph>
            </AttentionBlock>
         );
      }
      if (dataSendSMS) {
         if (dataSendSMS?.statusCode === 0) {
            return (
               <AttentionBlock mt="1rem" variant="Success">
                  <Paragraph>SMS byla úspěšně odeslána</Paragraph>
               </AttentionBlock>
            );
         } else {
            return (
               <AttentionBlock mt="1rem" variant="Error">
                  <Paragraph>Odeslání zprávy se nezdařilo. Zkuste jiný způsob přihlášení prosím.</Paragraph>
               </AttentionBlock>
            );
         }
      } else if (errorSendSMS) {
         return (
            <AttentionBlock mt="1rem" variant="Error">
                  <Paragraph>Odeslání zprávy se nezdařilo. Zkuste jiný způsob přihlášení prosím.</Paragraph>
            </AttentionBlock>
         );
      }
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
         }}
      >
         <form onSubmit={handleFormSubmit}>
            {!wasSMSSend ? (
               <CustomInput
                  labelText="Rodné číslo"
                  // subtext="(Cizinci zadávají číslo pojištěnce)"
                  placeholderText="Zadejte rodné číslo bez lomítka"
                  testId="rc-smsAuth"
                  value={SMSForm.clientID}
                  onChange={(e) => {
                     setFormHandler(
                        "clientID",
                        checkLength(e.target.value,10),
                        "birthNumber"
                     );
                  }}
                  validationText={SMSFormValidation.clientID}
               />
            ) : (
               <CustomInput
                  labelText="SMS kód"
                  shouldAutoFocus={true}
                  placeholderText="Zadejte kód který vám přišel v SMS"
                  value={SMSForm.SMSCode}
                  onChange={(e) => {
                     setFormHandler("SMSCode", e.target.value, "text");
                  }}
                  validationText={SMSFormValidation.SMSCode}
               />
            )}
            {displayAttentionBlock()}
            <Button mt="1rem" type="Submit" variant={"PrimaryMedium"}>
               {wasSMSSend ? "Ověřit kód" : "Poslat SMS kód"}
            </Button>
         </form>
      </Box>
   );
};

export default SMSForm;
