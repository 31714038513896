type Char = string & { length: 1 };

const SanitizeNumber = (value: string | null | undefined) => {
	if (!value) return "";
	const validChars = "0123456789 ";
	const sanitizedValue = value
		.split("")
		.filter((char) => validChars.includes(char as Char))
		.join("");
	return sanitizedValue;
};

export const MAX_PHONE_NUMBER_LENGTH = 14;
export const SanitizePhoneNumber = (value: string | null | undefined) => {
	if (!value) return "";
	const validChars = "+0123456789 ";
	let plusCount = 0;

	const sanitizedValue = value
		.split("")
		.filter((char) => {
			if (char === "+") {
				plusCount++;
				if (plusCount > 1) return false;
			}
			return validChars.includes(char);
		})
		.join("");

	return sanitizedValue;
};

export default SanitizeNumber;
