import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { StoreType } from "../../../../../../redux/store/store";
import {
	MAX_PHONE_NUMBER_LENGTH,
	SanitizePhoneNumber,
} from "../../../../../../Utils/SanitizeInputs/SanitizeNumber";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const ThroutfulPersonPhoneNumberField: React.FC = () => {
	const dispatch = useDispatch();
	const fieldNameLowerCase = "throutfulPersonPhoneNumber";
	const fieldNameUpperCase =
		fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

	const fieldValue = useSelector(
		(state: StoreType) =>
			state.claimState.personModel.personThroutful[fieldNameLowerCase]
	);

	const visibilityMatrixes = useSelector(
		(state: StoreType) => state.visibilityMatrixesState
	);

	const claimFieldErrorsMessages = useSelector(
		(state: StoreType) => state.claimFieldErrorsState
	);
	const ref = React.useRef<HTMLDivElement>(null);
	useScrollToTopErrorInput(ref, fieldNameUpperCase);

	const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const sanitizedValue = SanitizePhoneNumber(e.target.value);
		if (sanitizedValue.length <= MAX_PHONE_NUMBER_LENGTH) {
			dispatch(
				updateSecondStepGeneralsNestedFields({
					value: sanitizedValue,
					sectionName: "personThroutful",
					field: fieldNameLowerCase,
				})
			);
		}
	};

	return (
		<CustomInput
			labelText="Telefon"
			placeholderText="Kdybychom s vámi potřebovali mluvit"
			value={(fieldValue as string) || ""}
			onChange={handlePhoneInputChange}
			validationText={handleShowErrorMessage(
				fieldNameUpperCase,
				claimFieldErrorsMessages
			)}
			dependencyValue={shouldRenderSpecField(
				"GeneralFields",
				fieldNameUpperCase,
				visibilityMatrixes
			)}
			inputRef={ref}
		/>
	);
};

export default ThroutfulPersonPhoneNumberField;
