import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../redux/store/store";
import CustomInput from "../../../../../Components/CustomInputs/CustomInput";
import { updateFifthStepAgentFields } from "../../../../../redux/reducers/claimReducer";
import {
	MAX_PHONE_NUMBER_LENGTH,
	SanitizePhoneNumber,
} from "../../../../../Utils/SanitizeInputs/SanitizeNumber";
import { shouldRenderSpecField } from "../../../../../redux/reducers/visibilityMatrixesReducer";
import React from "react";
import { useScrollToTopErrorInput } from "../../../../../CustomHooks/useScrollToTopErrorInput";

const AgentPnoneNumberField = () => {
	const dispatch = useDispatch();
	const fieldNameLowerCase = "agentPhoneNumber";
	const fieldNameUpperCase =
		fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

	const fieldValue = useSelector(
		(state: StoreType) =>
			state.claimState.reportModel.agentModel[fieldNameLowerCase]
	);

	const visibilityMatrixes = useSelector(
		(state: StoreType) => state.visibilityMatrixesState
	);

	const dependencyValue = useSelector(
		(state: StoreType) => state.claimState.reportModel.gdprAgreement
	);

	const { agentPhoneNumber } = useSelector(
		(state: StoreType) => state.authState.agentErorrs
	);
	const ref = React.useRef<HTMLDivElement>(null);
	useScrollToTopErrorInput(ref, fieldNameUpperCase);

	const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const sanitizedValue = SanitizePhoneNumber(e.target.value);
		if (sanitizedValue.length <= MAX_PHONE_NUMBER_LENGTH) {
			dispatch(
				updateFifthStepAgentFields({
					value: sanitizedValue,
					field: fieldNameLowerCase,
				})
			);
		}
	};

	return (
		<CustomInput
			labelText="Telefon"
			placeholderText="Uveďte telefonní číslo poradce bez předvolby"
			value={(fieldValue as string) || ""}
			onChange={handlePhoneInputChange}
			validationText={agentPhoneNumber}
			dependencyValue={
				shouldRenderSpecField(
					"GeneralFields",
					fieldNameUpperCase,
					visibilityMatrixes
				) && dependencyValue
			}
			inputRef={ref}
		/>
	);
};

export default AgentPnoneNumberField;
