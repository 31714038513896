import { Box } from "@mui/material";
import {
   Paragraph,
   Popover,
   TextInput,
} from "@nn-design-system/react-component-library";
import React from "react";
import { colourTextDefault } from "../../Config/colors";
import { DataStateType } from "@nn-design-system/react-component-library/dist/components/BareInput/index.types";

type CustomInputProps = {
   labelText: string;
   value: string;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   validationText: string;
   name?: string;
   placeholderText?: string;
   dependencyValue?: boolean | null;
   renderField?: boolean;
   dataState?: DataStateType | undefined;
   subtext?: string;
   popoverHeading?: string;
   popoverText?: string;
   popoverText2?: string;
   type?: string;
   maxCharacters?: string;
   shouldAutoFocus?: boolean;
   inputRef?: React.RefObject<HTMLDivElement>;
   testId?: string;
};

const CustomInput: React.FC<CustomInputProps> = ({
   labelText,
   value,
   onChange,
   validationText,
   name,
   placeholderText,
   dependencyValue = true,
   dataState,
   subtext,
   popoverHeading,
   popoverText,
   popoverText2,
   type,
   maxCharacters,
   shouldAutoFocus,
   inputRef,
   testId
}) => {
   return dependencyValue ? (
      <div ref={inputRef}>
         <Box
            sx={{
               position: "relative",
               "& > div > div > div": {
                  outlineColor: `${colourTextDefault} !important`,
                  "&:focus": {},
               },
            }}
         >
            <TextInput
               shouldAutoFocus={shouldAutoFocus}
               name={name}
               type={type}
               labelText={labelText}
               testId={testId}
               value={value}
               onChange={onChange}
               validationText={validationText}
               placeholderText={placeholderText}
               dataState={dataState}
               subtext={subtext}
               popoverElement={
                  popoverText ? (
                     <Popover
                        headingText={popoverHeading}
                        placement="top-start"
                        variant="Icon"
                     >
                        <Paragraph>{popoverText}</Paragraph>
                        <Paragraph>{popoverText2}</Paragraph>
                     </Popover>
                  ) : undefined
               }
            />
            {maxCharacters && (
               <Box
                  sx={{
                     position: "absolute",
                     right: "0rem",
                     opacity: "0.7",
                     top: "0rem",
                     "@media (max-width: 700px)": {
                        bottom: "-1.5rem",
                        top: "auto",
                     },
                  }}
               >
                  <Paragraph hasMargin={false} variant="Small">
                     Maximálně {maxCharacters} znaků.
                  </Paragraph>
               </Box>
            )}
         </Box>
      </div>
   ) : null;
};

export default CustomInput;
