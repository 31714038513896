import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../redux/reducers/tokenReducer';
import { setPersonModel, login, setUserAuthenticationStatus } from '../../redux/reducers/authReducer';
import useFetch from '../../CustomHooks/useFetch';
import { AttentionBlock, Button, Paragraph } from "@nn-design-system/react-component-library";

const BankIdRedirectPage: React.FC = () => {
    const [authenticated, setAuthenticated] = useState<boolean>(true);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        sendRequest: requestGetInsuredClient,
        data: dataGetInsuredClient,
        error: errorGetInsuredClient,
    } = useFetch();

    const {
        sendRequest: requestBankIdCallback,
        data: dataBankIdCallback,
        error: errorBankIdCallback,
    } = useFetch();

    useEffect(() => {
        // Z url params vytahneme code a state od BankId
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            // odstranime code a state z url
            window.history.replaceState({}, document.title, window.location.pathname);
            const encodedCode = encodeURIComponent(code);
            const encodedState = encodeURIComponent(state);

            // provolame nas callback endpoint se ziskanym code a state
            requestBankIdCallback({
                method: 'GET',
                url: `${process.env.REACT_APP_BaseURL}${process.env.REACT_APP_BANK_ID_CALLBACK}?code=${encodedCode}&state=${encodedState}`,
                withCredentials: true, // nutne nastavit na true, jinak nebude mit BE prisup ke cookies, kde je kontrolni state
                headers: {},
            });
        } else {
            console.error("Error obtaining code and state");
            setAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        if (dataBankIdCallback) {
            if (dataBankIdCallback.statusCode === 0) {
                dispatch(setToken(dataBankIdCallback.jwtToken));
                setAccessToken(dataBankIdCallback.jwtToken);
            } else {
                setAuthenticated(false);
            }
        } else if (errorBankIdCallback) {
            console.error('Error fetching authResponse:', errorBankIdCallback);
            setAuthenticated(false);
        }
    }, [dataBankIdCallback, errorBankIdCallback, dispatch]);

    useEffect(() => {
        if (accessToken) {
            callGetInsuredClient();
        }
    }, [accessToken]);

    const callGetInsuredClient = () => {
        requestGetInsuredClient({
            method: "POST",
            url: process.env.REACT_APP_BaseURL! + process.env.REACT_APP_GetInsuredClientList,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                personalId: null, // RC si BE vytahne ze soucasneho access tokenu, zde ho uz nemame k dispozici
            },
        });
    };

    useEffect(() => {
        if (dataGetInsuredClient) {
            try {
                dispatch(
                    setPersonModel({
                        firstName: dataGetInsuredClient.result.insuredPersonList[0].firstname,
                        lastName: dataGetInsuredClient.result.insuredPersonList[0].lastname,
                        personalId: dataGetInsuredClient.result.insuredPersonList[0].personalId,
                        insuredPersons: dataGetInsuredClient.result.insuredPersonList,
                    })
                );
                dispatch(login());
                dispatch(setUserAuthenticationStatus("authorized"));
                navigate("/personSelection");
            }
            catch (error) {
                console.error('Error processing dataGetInsuredClient:', error);
                setAuthenticated(false);
            }
        }
        else if (errorGetInsuredClient) {
            console.error('Error fetching dataGetInsuredClient');
            setAuthenticated(false);
        }
    }, [dataGetInsuredClient, errorGetInsuredClient, dispatch]);

    return (
        <div>
            {authenticated}
            {errorGetInsuredClient}
            {(authenticated === false) ? (
                <div>
                    <AttentionBlock mt="1rem" mb="1rem" variant="Warning">
                        <Paragraph>Nepodařilo se přihlásit pomocí Bank ID. Zkuste prosím jinou přihlašovací metodu.</Paragraph>
                    </AttentionBlock>
                    <Button onClick={() => navigate("/")}>Zpět na přihlášení</Button>
                </div>
            ) : (
                <div>
                    <AttentionBlock mt="1rem" mb="1rem" variant="Informative">
                        <Paragraph>Probíhá přihlašování, počkejte prosím...</Paragraph>
                    </AttentionBlock>
                </div>
            )}
        </div>
    );
};

export default BankIdRedirectPage;
